import { Route, Routes } from 'react-router-dom'
import { Login } from 'pages/login'
import { VerifyOtp } from 'pages/verifyOtp'
import { ResetPassword } from 'pages/account/resetPassword'
import { ForgotPasswordInputEmail } from 'pages/account/forgotPasswordInputEmail'
import { AnalyzeMicroFrontend } from 'microservice/analyze'
import { AdminMicroFrontend } from 'microservice/admin'
import { CaseformMicroFrontend } from 'microservice/caseform'
import { NotAuthorized } from 'pages/notAuthorized'
import { PageNotFound } from 'pages/pageNotFound'
import {
  URL_ADMIN_BASIC,
  URL_ADMIN_CLIENT_ROLE,
  URL_ADMIN_OTP,
  URL_ADMIN_RESOLVEX,
  URL_ADMIN_TIMESHEET,
  URL_ANALYTICS_GENERAL,
  URL_ANALYTICS_REASSURANCE,
  URL_ANALYTICS_RESOURCE,
  URL_ANALYTICS_TABLEAU,
  URL_CASEFORM_DOWNLOADS,
  URL_CASEFORM_GROUPS,
  URL_CASEFORM_PATIENTS,
  URL_CASEFORM_PATIENTS_ADD,
  URL_CASEFORM_RESOURCES,
  URL_CASEFORM_RESOURCES_ADD,
  URL_CASEFORM_SESSIONS,
} from 'utils/variables/constants'

export const AppRoute = (): JSX.Element => {
  const multiRoute = (paths: string[], element: JSX.Element) =>
    paths.map((path, index) => (
      <Route path={path} element={element} key={index} />
    ))

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify-resolvex-otp" element={<VerifyOtp />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/forgotPassword" element={<ForgotPasswordInputEmail />} />
      <Route path="/401" element={<NotAuthorized />} />
      {
        /* CASEFORM ROUTES */
        multiRoute(
          [
            URL_CASEFORM_PATIENTS,
            URL_CASEFORM_PATIENTS_ADD,
            URL_CASEFORM_SESSIONS,
            URL_CASEFORM_RESOURCES,
            URL_CASEFORM_RESOURCES_ADD,
            URL_CASEFORM_DOWNLOADS,
            URL_CASEFORM_GROUPS,
          ],
          <CaseformMicroFrontend />
        )
      }

      {
        /* ANALYTICS ROUTES */
        multiRoute(
          [
            URL_ANALYTICS_GENERAL,
            URL_ANALYTICS_RESOURCE,
            URL_ANALYTICS_REASSURANCE,
            URL_ANALYTICS_TABLEAU,
          ],
          <AnalyzeMicroFrontend />
        )
      }

      {
        /* ADMIN ROUTES */
        multiRoute(
          [
            URL_ADMIN_BASIC,
            URL_ADMIN_RESOLVEX,
            URL_ADMIN_TIMESHEET,
            URL_ADMIN_CLIENT_ROLE,
            URL_ADMIN_OTP,
          ],
          <AdminMicroFrontend />
        )
      }

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
