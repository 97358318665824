import { useRef, useState } from 'react'
import logo from '../logo.png'
import { useNavigate } from 'react-router-dom'
import { getContext } from 'utils/store'
import { useMutation } from '@apollo/client'
import { VERIFY_USER_OTP } from 'graphql/loginGql'
import {
  Typography,
  Box,
  Paper,
  Grid,
  Container,
  TextField,
  Theme,
  Backdrop,
  CircularProgress,
} from '@mui/material'

import { URL_CASEFORM_SESSIONS } from 'utils/variables/constants'
import { makeStyles } from '@mui/styles'
import { saveToken } from '@resolvex/resolvex-sdk'
// import { getDecodedToken } from '@resolvex/resolvex-sdk'

const useStyles = makeStyles((theme: Theme) => ({
  otpInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  otpBox: {
    width: '5.25rem',
    height: 'auto',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: '',
  },
  errorMessage: {
    fontSize: '1.25rem',
    color: 'red',
    marginTop: theme.spacing(2),
    display: 'none',
  },
  errorShow: {
    display: 'block',
  },
}))

const NUMBER_OF_DIGITS = 6
const initEmptyArray = () => new Array(NUMBER_OF_DIGITS).fill('')

export const VerifyOtp = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [otp, setOtp] = useState(initEmptyArray())
  const [otpError] = useState('')
  const otpBoxReference = useRef<HTMLInputElement[]>([])
  const { setIsOtpVerified, setAlertMessage, handleLogout } = getContext()

  const [isLoading, setIsLoading] = useState(false)
  const [verifyUserOtp] = useMutation(VERIFY_USER_OTP)

  async function handleChange(value: string, index: number) {
    const newArr = [...otp]
    newArr[index] = value
    setOtp(newArr)

    const missingDigit = newArr.some((e) => !e)
    if (missingDigit) {
      if (value && index < NUMBER_OF_DIGITS - 1) {
        setFocusToIndex(index + 1)
      }
      return
    }

    setIsLoading(true)
    setFocusToIndex(0)
    await verifyUserOtp({
      variables: {
        verifyUserOtpArgs: {
          otp: newArr.join(''),
        },
      },
    })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        if (data?.data?.verifyUserOtp) {
          saveToken(data?.data?.verifyUserOtp)
          // setIsLoading(false)
          setIsOtpVerified(true)
          navigate(URL_CASEFORM_SESSIONS)
          return
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        console.log(typeof e)
        const err = e.graphQLErrors[0]
        const statusCode: number = (
          err.extensions as { exception: { status: 0 } }
        ).exception.status

        // wrong otp
        if (statusCode === 400) {
          setAlertMessage(e.message)
          setOtp(initEmptyArray())
          setIsLoading(false)
          return
        }
        setAlertMessage(e.message + '. Redirecting to login page...')
        handleLogout(1500)
        return
      })
  }

  function setFocusToIndex(index: number) {
    otpBoxReference.current[index].focus()
    console.log(otpBoxReference)
  }

  function handleBackspaceAndEnter(
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number
  ) {
    e.stopPropagation()
    const target = e.target as HTMLInputElement
    if (e.key === 'Backspace' && !target.value && index > 0) {
      setFocusToIndex(index - 1)
    }
  }

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Grid container component={Box} maxWidth={640} rowSpacing={2}>
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography variant="h3" color="primary.main">
            OTP Verification
          </Typography>
          <Typography variant="subtitle1">
            Enter your OTP below from your Authenticator
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <img
            style={{ width: '100%', height: 'auto' }}
            src={logo}
            alt="resolvexLogo"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            component={Paper}
            elevation={5}
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              p: 2,
            }}
          >
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {otp.map((digit, index) => (
                  <TextField
                    autoFocus={index === 0}
                    key={index}
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => handleBackspaceAndEnter(e, index)}
                    inputRef={(reference) => {
                      if (reference !== null) {
                        otpBoxReference.current[index] = reference
                      }
                    }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 1, // Limit to a single character
                      style: { textAlign: 'center', fontSize: '30px' },
                      // type: 'number', // Ensure only numbers can be entered
                    }}
                    sx={{
                      width: '80px', // Adjust the width as needed
                      height: '80px', // Set a fixed height
                      // padding: '8px', // Add padding inside the box
                      '& .MuiOutlinedInput-root': {
                        height: '100%', // Ensure the input field matches the height
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)', // Adjust border color as needed
                      },
                      '&:focus-within': {
                        borderColor: 'blue', // Change border color on focus
                      },
                    }}
                  />
                ))}
              </Box>
              <Backdrop
                sx={(theme) => ({
                  color: '#fff',
                  zIndex: theme.zIndex.drawer + 1,
                })}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Typography
                variant="body1"
                className={`${classes.errorMessage} ${
                  otpError ? classes.errorShow : ''
                }`}
              >
                {otpError}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
